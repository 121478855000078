.sideNav {
  background-color: #2f4050;
  height: 100vh;
  position: fixed;
  width: 220px;
  display: flex;
  flex-direction: column;
  font-family: 'open sans';
}

.top {
  display: block;
  color: #dfe4ed;
  background: url('../../../../assets/images/header_profile.png') no-repeat
    center;
  background-size: cover;
}

.list a {
  text-decoration: none;
  color: #a7b1c2;
}
