.primary {   
  display: flex;
  gap: 5px;
  align-items: flex-end;
  margin: 0px;
}

.secondary {
  font-size: 11px;
  display: flex;
  align-items: center;
}

.footerBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.poweredByTixserve {
  max-height: 30px;
  max-width: 92.7px;
}
