.main-content {
  background-color: #f5f5f5;
  margin-left: 220px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}


.main-content main {
  flex: 1;
}

.app-layout {
  display: flex;
  flex-direction: column;
}

.main-content header {
  background-color: white;
  text-align: left;
  padding: 10px;
}

.main-content .footer {
  background-color: white;
  text-align: left;
  padding: 10px 20px;
}

.white-bg{
  background-color: white
}

#wrapper {
  width: 100%;
  overflow-x: hidden;
}

.wrapper {
  padding: 0 20px;
}

.wrapper-content {
  padding: 20px 10px 40px;
}

#page-wrapper {
  padding: 0 15px;
  min-height: 568px;
  position: relative !important;
}

