.MuiSelect-root {
  width: auto;
}

.MuiTableCell-root {
  padding: 4px;
  border: none;
}

.mainBody {
  border-style: solid;
}


.alert-ticket-table {
  padding: 10px;
  text-align: center;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.alert-warning-ticket-table {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}

.headerIcon-ticket-table {
  width: 14px;
  height: 14px;
}