.searchForm {
  display: flex;
  align-items: stretch;
 
}

.searchTextField {
  display: flex;
  flex: 2;
  margin-right: 16px;
  max-width: 320px;
  background-color: #ffffff;
  border-radius: 8px;
  height: 36px;
 
}

.searchTextField-label {
  display: flex;
  flex: 2;
  margin-right: 16px;
  max-width: 320px;
  border-radius: 8px;
}

.searchInputRoot {
  border-radius: 8px;
  height: 36px;
}

.searchInputInput {
  padding: 10px;
}

.searchInputLabel {
  font-size: 15px;
  margin-top: -5px;
  color: rgb(103, 106, 108);
}

.MuiInputLabel-shrink {
  margin-top: 0px;
}

.searchGrid {
  margin-bottom: 24px;
}
