.actions {
  display:flex;
  justify-content: flex-end;
}

.ticketStatusColumn {
  display: flex;
  white-space: nowrap;
}

.validationError {
  color: red
}