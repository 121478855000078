.active {
  background-color: #293846;
}

.active .leftGrid {
  background-color: #19aa8d;
  display: flex;
  width: 4px;
  float: left;
}

.active .rightGrid {
  color: white;
}
