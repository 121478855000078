.header {
  text-align: left;
  font-weight: 700;
}

.blueHeader {
  text-align: left;
  color: #007ab3;
  font-weight: 700;
}

.blueHeader:hover {
  cursor: pointer;
}

.text-center {
  text-align: center !important;
}

.w-50 {
  width: 50px;
}

.w-100 {
  width: 100px;
}

.w-180 {
  width: 200px;
}

.w-250 {
  width: 250px;
}

.w-300 {
  width: 300px;
}

.w-160 {
  width: 160px;
}

.w-max {
  width: 45%;
}

.iconButton-gridHead {
  padding: 2px;
  padding-bottom: 16px;
}