.closeButton-popup {
  position: absolute;
  right: 8px;
  top: 8px;
  color: grey;
}

.dialog-popup {
  width: 960px;
  margin: 0px !important;
  max-width: unset !important;
  padding: 0;
  height: 650px;
  max-height: 80vh;
  overflow-y: unset !important;
}

.dialog-simplePopup {
  padding: 0;
  max-width: unset;
  max-height: 80vh;
}

.copyEventModalContainer {
  width: 500px;
  padding: 8px;
}

.contentContainer-simplePopup {
  overflow: hidden;
}