.container-splashScreen {
  background-color: #2f4050;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.splash-layout-splashScreen {
  min-height: 100vh;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logo-name-splashScreen {
  font-size: 87px;
  letter-spacing: -10px;
  margin-bottom: 0px;
  margin-top: -11px;
  text-align: center;
  font-weight: bold;
  box-sizing: border-box;
  line-height: 95.7px;
  inline-size: 300px;
}

.footer-small-splashScreen {
  display: flex;
  justify-content: center;
}
