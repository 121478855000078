.page-heading{
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }

.grid-item{
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 10px;
    padding-top: 10px;
  }
  
.breadcrumb {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 10px;
    list-style: none;
    background-color: white;
    border-radius: 0.25rem;
  }

h2 {
    font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin-top: 10px !important;
    color:  rgb(103, 106, 108);
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: inherit;
    font-weight: 300;
    line-height: 1.1;
  }

.custom-link {
  color: rgb(103, 106, 108);
  font-weight: 400;
  text-decoration-line: none;
  } 

  .page-heading {
    padding: 10px 10px 20px 10px;
}

.white-bg {
    background-color: #ffffff;
}
.page-heading {
    border-top: 0;
    padding: 0px 10px 20px 10px;
}
.wrapper {
    padding: 0 20px;
}
.border-bottom {
    border-bottom: 1px solid #e7eaec !important;
}



