.button {
  text-align: center;
  display: flex;
  font-size: 13px;
  font-weight: 600;
}

.nameBox {
  display: flex;
  padding-left: 10px;
  min-height: 18.563px;
}

.rightGrid {
  display: flex;
  padding: 14px 20px 14px 25px;
  float: right;
}

.button:hover {
  background-color: #293846;
  color: white;
}

.button .rightGrid:hover {
  color: white;
}
