.box {
  top: 0;
  left: 0;
  padding: 25px 25px 25px 25px;
  overflow: hidden;
}

.logo {
    max-width: 100%;
}

.profile a {
  color: white;
  text-decoration: none;
  font-size: 24px;
  font-weight: 100;
}

.profile {
  margin-top: 20px;
  margin-bottom: 10px;
  min-height: 26.39px;
  width: 150px;
  color: white;
  overflow: hidden;
  padding-bottom: 2px;
}

.line-clamp-text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
}

.accountType {
  font-size: 12px;
}

