.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

.MuiCard-root {
  border-radius: 16px;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.MuiTabs-flexContainer {
  justify-content: center;
}

.MuiTableCell-root {
  font-family: inherit;
}

.MuiDialog-paper {
  background-color: '#FFFFFF';
  overflow-y: visible;
  border-radius: 8px;
}

.MuiAppBar-root {
  box-shadow: none;
  align-items: center;
}

.MuiAppBar-colorPrimary {
  background-color: white;
  color: black;
}

.MuiTabs-indicator {
  display: flex;
  justify-content: center;
  background-color: #007ab3;
  border-radius: 7px;
  height: 20px;
  width: 80%;
  position: absolute;
  top: -12px;
}

.MuiTab-root {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  text-transform: none;
  color: #444444;
  margin: 10px;
}

.MuiSvgIcon-root {
  width: 1.5rem;
  height: 1.5rem;
}

.MuiCardActionArea-root {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.MuiCardContent-root {
  flex-grow: 1;
}

.MuiCardHeader-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.MuiCardHeader-title {
  font-size: 2rem;
  line-height: 2.5rem;
}

.MuiCardHeader-subheader {
  font-size: 1.5rem;
  line-height: 2.5rem;
  color: inherit;
}

.defaultButton {
  font-weight: bold;
  margin-right: 24px;
  max-width: 120px;
  flex: 1;
  border-radius: 8px;
  font-size: 14px;
}

.textButton {
  font-weight: bold;
  margin-right: 24px;
  border-radius: 8px;
  font-size: 14px;
}

.MuiTabs-root-margin {
  margin-top: -20px;
}

body {
  font-family: 'open sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  background-color: #2f4050;
  font-size: 13px;
  color: #676a6c;
  overflow-x: hidden;
}
