.row:nth-child(odd) {
  background-color: white;
}

tr:first-child td:first-child {
  border-top-left-radius: 24px;
}

tr:first-child td:last-child {
  border-top-right-radius: 24px;
}

tr:last-child td:first-child {
  border-bottom-left-radius: 24px;
}

tr:last-child td:last-child {
  border-bottom-right-radius: 24px;
}

.smallGrey {
  font-size: 12px;
  color: black;
  opacity: 50%;
}

.blueText {
  color: #007ab3;
}

.redText {
  color: red;
}

.italicText {
  font-style: italic;
}

.VALIDATED {
  color: #006600;
}

.NOTIFIED {
  color: #ff8800;
}

.DOWNLOADED {
  color: #009900;
}

.arrow {
  color: #c0c0c0;
}

.delete {
  color: #ff0000;
}

.edit {
  color: #007ab3;
}

.MuiSelect-root {
  width: auto;
}
.MuiTableCell-root {
  padding: 4px;
  border: none;
}

.mainBody {
  border-style: solid;
}

.ticket-counter-small {
  font-size: 12px;
  position: relative;
  top: -6px;
}

.align-numbers{
  text-align: center;
}

.checkbox-padding{
  padding-right: 2px;
  padding-left: 15px;
}
.first-checkbox-padding{
  padding-right: 2px;
}

.column-width{
  max-width: 300px;
  overflow-x: auto;
  vertical-align: middle;
  padding-left: 20px;
  padding-right: 20px;
}

.inactive-row {
  opacity: 0.5;
  font-style: italic;
}

.bold{
  font-weight: bolder;
}

.linkTransfer {
  cursor: pointer;
  color: #337ab7;
  text-decoration: none;
  background-color: transparent;

  margin-top: -10px;
}

.table-overflow{
  overflow-x: auto;
  margin-top: 20px;
  background-color: unset;
  box-shadow: none;
}

.table-width{
  min-width: 1300px;
  overflow-x: auto;
  vertical-align: middle;
}

small, .small {
  font-size: 85%;
}

.table-width tbody > tr > td {
  vertical-align: middle;
  border-top: none;
}
